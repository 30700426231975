import "vite/modulepreload-polyfill";
// import { onLCP, onFID, onCLS } from "web-vitals/attribution";
import { dynamicRenderer } from "@utils";

window.addEventListener('vite:preloadError', (event) => {
  // window.location.reload() // for example, refresh the page
  console.log("error with preload", event)
})

document.addEventListener(
  "DOMContentLoaded",
  function () {
    dynamicRenderer([
      /* PLOP_INDEX_IMPORT */
{selector: ".js-matrix-pdf-generator", name: "components/MatrixPdfGenerator/MatrixPdfGenerator.init.js"},
{selector: ".js-matrix-please-read", name: "components/MatrixPleaseRead/MatrixPleaseRead.init.js"},
{selector: ".js-disclaimer", name: "components/MatrixDisclaimer/MatrixDisclaimer.init.js"},
{selector: ".js-dm-rte", name: "components/MatrixRte/MatrixRte.init.js"},
{selector: ".js-quick-search", name: "components/MatrixQuickSearch/MatrixQuickSearch.init.js"},
{selector: ".js-tabbed-promo", name: "components/MatrixTabbedPromo/MatrixTabbedPromo.init.js"},
{selector: ".js-rte", name: "components/MatrixRte/MatrixRte.init.js"},
{selector: ".js-matrix-related-products", name: "components/MatrixRelatedProducts/MatrixRelatedProducts.render.js"},
      {
        selector: ".js-tabbed-container", 
        name: "components/MatrixTabbedContainer/MatrixTabbedContainer.init.js"
      },
      {
        selector: ".js-matrix-terms-of-service", 
        name: "components/MatrixTermsOfService/MatrixTermsOfService.init.js"
      },
      {
        selector: ".js-dm-dynamic-results-mount", 
        name: "components/MatrixDynamicResults/MatrixDynamicResults.init.js"
      },
      {
        selector: ".js-dm-search-model",
        name: "components/MatrixSearchModel/MatrixSearchModel.init.js",
      },
      {
        selector: ".js-dm-step-search",
        name: "components/MatrixStepSearch/MatrixStepSearch.init.js"
      },
      {
        selector: ".js-dm-carousel-swiper",
        name: "components/PromoCarousel/PromoCarousel.init.js",
      },
    ]);
    // onCLS(console.log);
    // onFID(console.log);
    // onLCP(console.log);
    
  },
  false,
);
